<template>
  <div>
    <el-card>
      <!-- 面包屑导航 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods' }"
          >商品列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>商品规格管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <span>{{ SpeGoodsList }}</span> -->

      <!-- 顶部搜索区域 -->
      <!-- <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="addobj()">添加商品规格</el-button>
        </el-col>
      </el-row> -->

      <!-- 主要内容 -->
      <el-table :data="SpeGoodsList" border style="width: 100%">
        <el-table-column prop="id" label="规格id"> </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope" width="200px">
            <img
              :src="scope.row.mainPic"
              style="width: 100px; height: 100px; border: none"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="规格名称"> </el-table-column>
        <el-table-column prop="specialRate" label="特价"> </el-table-column>
        <el-table-column prop="costPrice" label="现价"> </el-table-column>
        <el-table-column prop="stock" label="库存"> </el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="showEditDialog(scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" @click="removeGoodsById(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="Pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.currentPage"
          :page-sizes="[1, 3, 10, 20]"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 编辑商品对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 主要内容 -->
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="规格ID" prop="id">
          <el-input v-model="editForm.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="规格名称" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="现价" prop="costPrice">
          <el-input v-model="editForm.costPrice"></el-input>
        </el-form-item>
        <el-form-item label="特价" prop="specialRate">
          <el-input v-model="editForm.specialRate"></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品描述" prop="remark">
          <el-input v-model="editForm.remark"></el-input>
        </el-form-item> -->
        <el-form-item label="库存" prop="stock">
          <el-input v-model="editForm.stock"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editGoodsInfo">确 定</el-button>
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // prop: ['SpeGoodsList'],
  data() {
    return {
      // 商品规格列表
      SpeGoodsList: [],

      pages: {
        query: '',
        currentPage: 1,
        pageSize: 3,
      },

      total: 0,

      // 商品规格
      SpeGoods: {},

      // 控制编辑商品规格对话框
      editDialogVisible: false,

      // 编辑属性
      editForm: {},
      // 编辑商品规格规则
      editFormRules: {
        id: [{ required: true, message: '请输入规格ID', trigger: 'blur' }],
        title: [{ required: true, message: '请输入规格名称', trigger: 'blur' }],
        remark: [
          { required: true, message: '请输入商品描述', trigger: 'blur' },
        ],
        costPrice: [{ required: true, message: '请输入现价', trigger: 'blur' }],
        specialRate: [
          { required: true, message: '请输入特价', trigger: 'blur' },
        ],
        stock: [{ required: true, message: '请输入库存', trigger: 'blur' }],
      },

      //存储主键ID
      ID: [],
    }
  },
  created() {
    this.getGoodsid()
    this.GetGoodsSkuToPage()
  },
  methods: {
    // 获取对应的商品ID
    getGoodsid() {
      var cs = this.$route.query.id
      // this.SpeGoodsList = this.$route.query
      console.log(cs)
      this.SpeGoods.currentPage = this.pages.currentPage
      this.SpeGoods.pageSize = this.pages.pageSize
      this.SpeGoods.goodsId = cs
      console.log(this.SpeGoods)
    },

    // 请求商品规格列表
    GetGoodsSkuToPage() {
      this.$api.GetGoodsSkuToPage(this.SpeGoods).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求商品规格失败')
        } else {
          this.$message.success('请求商品规格成功')
        }
        console.log(res)
        this.SpeGoodsList = res.data.records
        this.total = res.data.total
      })
    },

    // 监听页码大小 也就是下拉框
    handleSizeChange(newSize) {
      this.pages.pageSize = newSize
      // 刷新列表
      this.GetGoodsSkuToPage()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange(newPage) {
      this.pages.currentPage = newPage
      // 刷新列表
      this.GetGoodsSkuToPage()
    },

    // 点击显示出编辑商品对话框
    showEditDialog(row) {
      this.editForm = row
      console.log(this.editForm)
      this.editDialogVisible = true
    },
    // 编辑商品规格
    editGoodsInfo() {
      // console.log(123)
      this.$refs.editFormRef.validate((valid) => {
        // console.log(valid);
        if (!valid) return
        // 下面发起真正的请求
        this.$api.UpdateGoodsSku(this.editForm).then((res) => {
          if (res.code !== 200) {
            // console.log();
            this.$message.error('修改商品规格失败')
          } else {
            this.$message.success('修改商品规格成功')
          }
          console.log(res)
          // 关闭对话框
          this.editDialogVisible = false
          // 刷新列表
          this.GetGoodsSkuToPage()
        })
      })
    },

    // 监听分类编辑对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },

    // 删除商品规格
    async removeGoodsById(id) {
      console.log(id)
      this.ID.push(id)
      // 去重
      this.ID = [...new Set(this.ID)]
      console.log(this.ID)
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商品规格, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      // console.log(confirmResult)
      // 如果用户确认删除就返回个 confirm
      // 不删除就返回cancel
      if (confirmResult !== 'confirm') {
        // 当页面关闭或者取消的时候清空数组
        this.ID.splice(0, this.ID.length)
        // console.log(this.ID)
        return this.$message.info('取消了删除')
      }
      // 下面发起真正的删除请求
      this.$api.BatchDeleteGoodsSku(this.ID).then((res) => {
        if (res.code !== 200) {
          this.$message.error('删除商品规格失败')
        } else {
          this.$message.success('删除商品规格成功')
        }
        // 刷新列表
        this.GetGoodsSkuToPage()
      })
    },

    // 跳转到添加页码
    addobj() {
      this.$router.push('/addSpeGoods')
    },
  },
}
</script>

<style lang="scss" scoped></style>
